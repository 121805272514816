
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-main">
                <p>Some text here</p>
            </div>
        </footer>
    )
}
export default Footer